import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import melSafetyTable from "../../../assets/MEL-Advanced-006-safety-small-V2.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions in Patients With Advanced Melanoma | HCP`,
    keywords: `keytruda advanced melanoma adverse reactions`,
    description: `Learn about selected immune-mediated adverse reactions to KEYTRUDA® (pembrolizumab) in patients with advanced melanoma.`,
    schemaJsonLD: [
       `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/melanoma-monotherapy/","@type":"MedicalWebPage","name":"Selected Adverse Reactions in KEYNOTE-006","description":"In KEYNOTE-006, KEYTRUDA was discontinued due to adverse reactions in 9% of 555 patients with advanced melanoma; adverse reactions leading to permanent discontinuation in more than one patient were colitis (1.4%), autoimmune hepatitis (0.7%), allergic reaction (0.4%), polyneuropathy (0.4%), and cardiac failure (0.4%). The most common adverse reactions (≥20%) with KEYTRUDA were fatigue (28%), diarrhea (26%), rash (24%), and nausea (21%)."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/melanoma-monotherapy/","@type":"MedicalAudience","audienceType":" health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const melMonoSafetyAlt = "Selected Adverse Reactions in KEYNOTE-006"

//from /src/util/data/indications.js
const indicationId = 2;
const jobCode = jobCodes[2].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Safety Data",
            items: [
                { text: 'Adjuvant Therapy for Melanoma', url: '/safety/adverse-reactions/melanoma-adjuvant-therapy/' },
                ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Selected Adverse Reactions in KEYNOTE&#8288;-&#8288;006'}
]

const melAdvancedDataTableFootnotes = [
    {
        label: 'c.',
        text: 'Adverse reactions occurring at same or higher incidence than in the ipilimumab arm.'
    },
    {
        label: 'd.',
        text: 'Graded per NCI-CTCAE v4.0.'
    },
    {
        label: 'e.',
        text: 'Includes rash, rash erythematous, rash follicular, rash generalized, rash macular, rash maculopapular, rash papular, rash pruritic, and exfoliative rash.'
    },
    {
        label: 'f.',
        text: 'Includes skin hypopigmentation.'
    },
]

const melAdvancedDataTableDefinitions ="Q2W = every 2 weeks; Q3W = every 3 weeks; NCI-CTCAE = National Cancer Institute-Common Terminology Criteria for Adverse Events."

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location } >
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                       <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                <p>In KEYNOTE&#8288;-&#8288;006, permanent discontinuation of KEYTRUDA due to adverse events occurred in 9% of patients.</p>
                                <p>Most of the common (≥10%) adverse reactions with KEYTRUDA were Grade 1 or 2.</p>
                                <p>Other clinically important adverse reactions occurring in ≥10% of patients receiving KEYTRUDA were diarrhea (26%), nausea (21%), and pruritus (17%).</p>
                            </TextBlock>
                            <DataTable
                                title='Occurring in ≥10% of Patients Receiving KEYTRUDA<sup>c</sup>'
                                footnotes={melAdvancedDataTableFootnotes}
                                definitions={melAdvancedDataTableDefinitions}
                                mobileImage={() => <img src={melSafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={melMonoSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/mel-advanced-006-safety-large-v-2.png" alt={melMonoSafetyAlt}/>}
                            />
                        </PageSection>
                    </TemplateColumn>
                {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
